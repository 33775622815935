import * as React from 'react';
import { Assets, Product } from 'components/Catalog/Interfaces/List/IListResponse';
import classNames from 'classnames';
import { useLocation } from 'react-router';
import { parse } from 'query-string';

interface IProps {
    products: Product[];
}

const Products = (props: IProps) => {
    const location = useLocation();
    const queryString = parse(location.search);
    const useListView = (queryString.mode && queryString.mode === 'list') || false;
    const { products } = props;
    return (
        <React.Fragment>
            <div className="layout-products">
                <div className={classNames('layout-products__list light', { list: useListView })}>
                    {products &&
                        products.map((product, index) => (
                            <div className="layout-products__container" key={index}>
                                <div className="box-product">
                                    <a href={product.url} className="box-product__link">
                                        {product.name}
                                    </a>
                                    <div className="box-product__image">
                                        {product.priceOld && <div className="product-sale">%</div>}
                                        <span>
                                            <img src={product.image.image_url} alt={product.name} />
                                        </span>
                                    </div>
                                    <div className="box-product__content">
                                        <div className="box-product__text">
                                            {product.preTitle && product.preTitle !== '0' ? (
                                                <div className="box-product__pre-title">{product.preTitle}</div>
                                            ) : (
                                                ''
                                            )}
                                            <div className="box-product__title">{product.name}</div>
                                            {product.description && (
                                                <div className="box-product__description">{product.description}</div>
                                            )}
                                            {product.configurablePriceFromLabel && (
                                                <div className="box-product__price from">
                                                    {product.configurablePriceFromLabel}
                                                </div>
                                            )}
                                            <div className="box-product__price">{product.price}</div>
                                            {product.priceOld && (
                                                <div className="box-product__price old">{product.priceOld}</div>
                                            )}
                                        </div>
                                        {product.swatches && (
                                            <ul className="box-product__swatches">
                                                {Object.values(product.swatches).map((swatches) => {
                                                    return Object.values(swatches).map((swatch, swatchIndex) => {
                                                        if (swatch.value) {
                                                            return (
                                                                <li key={swatchIndex}>
                                                                    <span style={{ background: swatch.value }} />
                                                                </li>
                                                            );
                                                        }
                                                        return <React.Fragment key={swatchIndex} />;
                                                    });
                                                })}
                                            </ul>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </React.Fragment>
    );
};

export default Products;
