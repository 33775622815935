import * as React from 'react';
import { memo, useCallback, useEffect, useReducer, useState } from 'react';
import { useMutation } from 'redux-query-react';
import productInbankRentalRequest from 'data/requests/product/inbankRental/productInbankRentalRequest';
import {
    IConfigPrice,
    IProductConfig,
    ISelectedProductInformation,
    ISmartdealB2BConfig,
} from 'components/Catalog/Product/Detail';
import formProductRequest, { IProductRequest } from 'data/requests/product/data/formProductRequest';
import checkoutInbankRentalRequest from 'data/requests/product/inbankRental/checkoutInbankRentalRequest';
import Toaster from 'vkid-ui/lib/Components/Toaster/index';
import CartButton, { ICartTimeToRenewValue } from 'components/Catalog/Product/CartButton';
import crossSaleRequestBySku from 'data/requests/product/crossSaleRequestBySku';
import { ISwatchConfig } from 'components/Catalog/Product/Configurator';
import { CartType } from 'components/Cart/Enum/CartType';
import cartButtonRequest from 'data/requests/product/cartButton/cartButtonRequest';
import { empty } from '../../../helpers/empty';
import {
    ISliceConfiguration,
    slice3ConfigReducer,
} from 'components/Catalog/Product/DetailComponent/Slice3/Slice3ConfigReducer';
import RegularCartOption from 'components/Catalog/Product/DetailComponent/RegularCart/RegularCartOption';
import Slice3Option from 'components/Catalog/Product/DetailComponent/Slice3/Slice3Option';
import InbankRentalOption, {
    IRenewalPeriod,
} from 'components/Catalog/Product/DetailComponent/InbankRentalCartOption/InbankRentalOption';
import useLastRegularOrSlice, { setLastIsRegularOrSlice } from 'components/Cart/Hooks/useLastRegularOrSlice';
import SmartdealB2BButton from 'components/SmartdealB2B/SmartdealB2BButton';
import EstoSlice3Option, {
    IEstoSlice3Config,
} from 'components/Catalog/Product/DetailComponent/EstoSlice3/EstoSlice3Option';

interface IProps {
    monthlyFee?: {
        label: string;
        value: string;
    };
    productId?: string;
    inbankRentalLabels?: string[];
    aboutInbankRental?: string;
    inbankRentalTermsUrl?: string;
    timeToRenew?: string;
    cartTimeToRenew?: number[];
    productConfig: IProductConfig;
    selectedProductInformation: ISelectedProductInformation;
    price: IConfigPrice;
    oldPrice?: string;
    metaResponse?: string;
    addToCartOrigin: () => void;
    children?: any;
    swatchConfig: ISwatchConfig;
    inbankRentalEnabled?: boolean;
    productDetailViewLogo?: string;
    chooseRenewalPeriodLabel?: string;
    slice3MethodEnabled?: boolean;
    estoSlice3Config: IEstoSlice3Config;
    slice3Config?: ISliceConfiguration;
    cartFlowType: string;
    extraInfo?: string;
    useInsuranceLabel?: string;
    setCartFlowType: (value: string) => void;
    smartdealB2BConfig: ISmartdealB2BConfig;
    isLoadingAvailability: boolean;
}

const CartWrapper = (props: IProps) => {
    const {
        monthlyFee,
        productId,
        price,
        oldPrice,
        selectedProductInformation,
        productConfig,
        inbankRentalEnabled,
        slice3MethodEnabled,
        estoSlice3Config,
        cartFlowType,
        setCartFlowType,
        extraInfo,
        useInsuranceLabel,
        smartdealB2BConfig,
        isLoadingAvailability,
    } = props;
    const [fee, setFee] = useState(monthlyFee);
    const [disableInbankRentalOption, setDisableOption] = useState<boolean | undefined>(false);
    const [inbankRentalApiResponse, setInbankRentalApiResponse] = useState(props.metaResponse);
    const [inbankRentalLabels, setInbankRentalLabels] = useState(props.inbankRentalLabels);
    const [productDetailViewLogo, setProductDetailViewLogo] = useState();
    const [chooseRenewalPeriodLabel, setChooseRenewalPeriodLabel] = useState();
    const [inbankRentalModalFitLabels, setInbankRentalModalFitLabels] = useState();
    const [inbankRentalExtraInfo, setInbankRentalExtraInfo] = useState();
    const [inbankRentalUseInsuranceLabel, setInbankRentalUseInsuranceLabel] = useState();
    const [timeToRenew, setTimeToRenew] = useState();
    const [renewalPeriods, setRenewalPeriods] = useState<IRenewalPeriod[]>([]);
    const [currentRenewalPeriod, setCurrentRenewalPeriod] = useState('');
    const [notSamePrioritySkus, setNotSamePrioritySkus] = useState<string[]>([]);
    const [isHero, setIsHero] = useState<boolean>(false);
    const [cartHeroProducts, setCartHeroProducts] = useState<ICartTimeToRenewValue[]>();
    const [aboutInbankRental, setAboutInbankRental] = useState(props.aboutInbankRental);
    const [inbankRentalTermsUrl, setInbankRentalTermsUrl] = useState(props.inbankRentalTermsUrl);
    const [buttonConf, setButtonConf] = useState(productConfig.addToCartButton);
    const [isInbankRentalProduct, setIsInbankRentalProduct] = useState(false);

    const [lastRegularOrSlice, setLastRegularOrSlice] = useLastRegularOrSlice();

    const [slice3Config, dispatchSlice] = useReducer(slice3ConfigReducer, props.slice3Config);

    const [{}, inbankRentalRequest] = useMutation((id: string, period: string) =>
        productInbankRentalRequest(id, period),
    );
    const [{}, checkCrossSaleRequest] = useMutation((sku: string) => crossSaleRequestBySku(sku));
    const [{}, addProductRequest] = useMutation((data: IProductRequest) => checkoutInbankRentalRequest(data));
    const [{}, buttonRequest] = useMutation((id: string) => cartButtonRequest(id));
    const isInterest = buttonConf.interestConfig !== undefined;

    useEffect(() => {
        if (productId) {
            buttonRequest(productId).then((response) => setButtonConf(response.body));
        }
    }, [productId]);

    const addToCart = useCallback(async () => {
        const productRequestData: IProductRequest = formProductRequest(
            '',
            props.productConfig.sku.value,
            props.productConfig.type,
            props.selectedProductInformation.selectedAttributes,
            inbankRentalApiResponse,
        );

        const response = await addProductRequest(productRequestData);
        if (response.status !== 200) {
            Toaster.addToast({
                intent: 'danger',
                text: response.body.message,
                asHtml: true,
            });
        } else {
            const checkCrossSaleResponse = await checkCrossSaleRequest(
                selectedProductInformation.sku || props.productConfig.sku.value,
            );

            if (checkCrossSaleResponse.body.url) {
                window.location.href = checkCrossSaleResponse.body.url;
            } else {
                Toaster.addToast({
                    intent: 'success',
                    text: response?.body?.message,
                    asHtml: true,
                });
            }
        }
    }, [addProductRequest]);

    const RequestInbankRental = () => {
        if (productId && !empty(inbankRentalEnabled)) {
            if (productId.toString() === productConfig.id.toString() && !empty(productConfig.isConfigurableProduct)) {
                // Do not query configurable data
                return;
            }
            inbankRentalRequest(productId, currentRenewalPeriod).then((response) => {
                setFee(response.body?.monthlyFee);
                setInbankRentalLabels(response.body?.inbankRentalLabels);
                setInbankRentalApiResponse(response.body?.metaResponse);
                setTimeToRenew(response.body?.timeToRenew);
                setCartHeroProducts(response.body?.cartHeroProducts);
                setInbankRentalModalFitLabels(response.body?.modalLabels);
                setDisableOption(response.body?.disableOption);
                setAboutInbankRental(response.body?.aboutInbankRental);
                setInbankRentalTermsUrl(response.body?.inbankRentalTermsUrl);
                setNotSamePrioritySkus(response.body?.notSamePrioritySkus);
                setIsHero(response.body?.isHero);
                setRenewalPeriods(response.body?.renewalPeriods);
                setProductDetailViewLogo(response.body?.productDetailViewLogo);
                setChooseRenewalPeriodLabel(response.body?.chooseRenewalPeriodLabel);
                setInbankRentalExtraInfo(response.body?.extraInfo);
                setInbankRentalUseInsuranceLabel(response.body?.useInsuranceLabel);
                setIsInbankRentalProduct(response.body?.isInbankRentalProduct);
                if (
                    !currentRenewalPeriod &&
                    response.body?.renewalPeriods &&
                    response.body?.renewalPeriods.length > 0 &&
                    response.body?.renewalPeriods[0].value !== undefined
                ) {
                    setCurrentRenewalPeriod(response.body?.renewalPeriods[0].value ?? '');
                }
            });
        }
    };

    useEffect(() => {
        RequestInbankRental();
    }, [productId, inbankRentalEnabled, currentRenewalPeriod]);

    useEffect(() => {
        window.addEventListener('hasInsuranceChanged', RequestInbankRental);
        return function cleanup() {
            window.removeEventListener('hasInsuranceChanged', RequestInbankRental);
        };
    });

    useEffect(() => {
        if (!empty(timeToRenew)) {
            window.dispatchEvent(
                new CustomEvent('financial-calculator-default-period-set', {
                    detail: {
                        defaultPeriod: timeToRenew,
                    },
                }),
            );
        }
    }, [timeToRenew]);

    const isOnlyOneMethod = empty(inbankRentalEnabled) && empty(slice3MethodEnabled);

    return (
        <React.Fragment>
            <ul className="product-pricing__options">
                <InbankRentalOption
                    cartFlowType={cartFlowType}
                    setCartFlowType={setCartFlowType}
                    isInterest={isInterest}
                    aboutInbankRental={aboutInbankRental}
                    disableInbankRentalOption={disableInbankRentalOption}
                    inbankRentalEnabled={inbankRentalEnabled}
                    inbankRentalLabels={inbankRentalLabels}
                    fee={fee}
                    inbankRentalTermsUrl={inbankRentalTermsUrl}
                    renewalPeriods={renewalPeriods}
                    setRenewalPeriod={setCurrentRenewalPeriod}
                    productDetailViewLogo={productDetailViewLogo}
                    chooseRenewalPeriodLabel={chooseRenewalPeriodLabel}
                    selectedRenewalPeriod={currentRenewalPeriod}
                    useInsuranceLabel={inbankRentalUseInsuranceLabel}
                    extraInfo={inbankRentalExtraInfo}
                    isInbankRentalProduct={isInbankRentalProduct}
                />
                <RegularCartOption
                    isInterest={isInterest}
                    isOnlyOneMethod={isOnlyOneMethod}
                    productConfig={productConfig}
                    cartFlowType={cartFlowType}
                    setCartFlowType={setCartFlowType}
                    price={price}
                    oldPrice={oldPrice}
                    selectedProductInformation={selectedProductInformation}
                />
                <Slice3Option
                    dispatchSlice={dispatchSlice}
                    isInterest={isInterest}
                    productId={productId}
                    setCartFlowType={setCartFlowType}
                    productConfig={productConfig}
                    cartFlowType={cartFlowType}
                    selectedProductInformation={selectedProductInformation}
                    slice3Config={slice3Config}
                    slice3MethodEnabled={slice3MethodEnabled}
                />
                <EstoSlice3Option
                    isInterest={isInterest}
                    productId={productId}
                    setCartFlowType={setCartFlowType}
                    cartFlowType={cartFlowType}
                    estoSlice3Config={estoSlice3Config}
                />
            </ul>
            {productConfig.addToCartButton && (
                <CartButton
                    setCartFlowType={setCartFlowType}
                    slice3ModalLabels={slice3Config?.modalLabels ?? estoSlice3Config?.modalLabels}
                    isSliceValidationError={slice3Config?.isSliceValidationError}
                    isInbankRentalOverlay={true}
                    buttonConfig={buttonConf}
                    productId={selectedProductInformation?.productId}
                    addToCart={props.addToCartOrigin}
                    addToCartInbankRental={addToCart}
                    isInbankRentalAdd={cartFlowType === CartType.INBANK_RENTAL_CART}
                    isSlice3Add={cartFlowType === CartType.SLICE3_CART}
                    isEstoSlice3Add={cartFlowType === CartType.ESTO_SLICE3_CART}
                    isEstoSlice3ValidationError={estoSlice3Config?.product?.isSliceValidationError}
                    timeToRenew={timeToRenew}
                    notSamePrioritySkus={notSamePrioritySkus}
                    isHero={isHero}
                    cartHeroProducts={cartHeroProducts}
                    productConfig={productConfig}
                    inbankRentalFee={fee}
                    inbankRentalModalFitLabels={inbankRentalModalFitLabels}
                    selectedProductInformation={selectedProductInformation}
                    swatchConfig={props.swatchConfig}
                    lastRegularOrSlice={lastRegularOrSlice}
                    setLastRegularOrSlice={setLastRegularOrSlice}
                    isLoadingAvailability={isLoadingAvailability}
                />
            )}
            {!!inbankRentalEnabled &&
                smartdealB2BConfig.enabled &&
                (selectedProductInformation.sku || props.productConfig.sku.value) && (
                    <SmartdealB2BButton
                        config={smartdealB2BConfig}
                        sku={selectedProductInformation.sku || props.productConfig.sku.value}
                    />
                )}
        </React.Fragment>
    );
};

export default memo(CartWrapper);
