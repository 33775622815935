import TimeRemaining from 'vkid-ui/lib/Applications/Checkout/Components/TimeRemaining/index';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useOverlays from '../../components/overlay/Overlay';
import { useMutation, useRequest } from 'redux-query-react';
import { postRequest } from 'data/requests/postRequest';
import { useSelector } from 'react-redux';
import TimeRunningOut from '../../components/rx/Overlay/TimeRunningOut';
import { formDataRequest } from '../../data/requests/formDataRequest';
import { ResponseStatusEnum } from '../../enums/ResponseStatus';
import ClosingTime from '../../components/rx/Overlay/ClosingTime';
import RedirectAbsolute from '../../components/Redirect/RedirectAbsolute';

interface IProps {
    config?: IConfig;
}
interface IConfig {
    success: number;
    startTime: number;
    closingTime: number;
    countdownLength: number;
    popupThreshold: number;
    closeThreshold: number;
    currentTime: number;
}
const mapStateToProps = (state): IProps => {
    const config = state.entities.config ? JSON.parse(state.entities.config) : undefined;
    sessionStorage.setItem('currentTime', config?.currentTime?.toString());
    return {
        config,
    };
};
const RxTimer = () => {
    if (!window.isTrhEnabled) {
        return <React.Fragment />;
    }
    const configRequest = useRequest(postRequest({ type: 'config', url: 'rx/countdown', data: { action: 'query' } }));
    const [{}, renewRequest] = useMutation(() =>
        postRequest({ type: 'renew', url: 'rx/countdown', data: { action: 'renew' } }),
    );
    const { config } = useSelector(mapStateToProps);
    const initialTimer = useRef(0);
    const [startTime, setStartTime] = useState(0);
    const expiringOverlayShown = useRef(false);
    const closingOverlayShown = useRef(false);
    const clearRequestDone = useRef(false);
    const [{}, clearRequest] = useMutation(() =>
        formDataRequest({
            type: 'clearRequest',
            url: 'checkout/cart/updatePost',
            data: {
                update_cart_action: 'remove_cart_action|all',
                is_ajax: 1,
                form_key: window.form_key,
                test: 'test',
            },
        }),
    );
    const [timeRemaining, setTimeRemaining] = useState(initialTimer.current);
    const [redirect, setRedirect] = useState<any>();
    const { overlays, openOverlay } = useOverlays();
    const { t } = useTranslation();
    const currentTime = () => parseInt(sessionStorage.getItem('currentTime') || '0');
    const timeLeft = () => {
        if (config) {
            const counterStartTime = startTime ? startTime : config.startTime;
            if (counterStartTime > 0) {
                return config.countdownLength - (currentTime() - counterStartTime);
            }
        }
        return 0;
    };
    const closingTimeLeft = () => (config ? config.closingTime - config.currentTime : 0);

    const timer = setTimeout(() => {
        setTimeRemaining(timeLeft());
        sessionStorage.setItem('currentTime', (currentTime() + 1).toString());
    }, 1000);

    const clearAll = () => {
        clearTimeout(timer);
        if (!clearRequestDone.current) {
            clearRequestDone.current = true;
            clearRequest().then(() => {
                setRedirect({ to: `checkout/cart` });
            });
        }
    };

    const renewTime = () => {
        renewRequest().then((response) => {
            if (response.status === ResponseStatusEnum.ok) {
                const expandResponse: { success: number; startTime?: number; currentTime: number } = JSON.parse(
                    response.body,
                );
                if (expandResponse.success === 1) {
                    if (expandResponse?.startTime) {
                        sessionStorage.setItem('currentTime', expandResponse.currentTime?.toString());
                        setStartTime(expandResponse.startTime);
                        expiringOverlayShown.current = false;
                    }
                } else {
                    window.location.reload();
                }
            }
        });
    };

    if (initialTimer.current === 0 && config?.success === 1 && timeLeft() > 0) {
        initialTimer.current = timeLeft();
        setTimeRemaining(initialTimer.current);
        setStartTime(config.startTime);
    }
    if (timeLeft() < 0) {
        clearAll();
    }
    if (config && config?.success === 1) {
        if (
            !expiringOverlayShown.current &&
            timeLeft() <= config.popupThreshold &&
            overlays.indexOf('expiring') === -1
        ) {
            openOverlay('expiring', true);
            expiringOverlayShown.current = true;
        }
        if (
            !closingOverlayShown.current &&
            closingTimeLeft() <= config.closeThreshold &&
            overlays.indexOf('closing') === -1
        ) {
            openOverlay('closing', true);
            closingOverlayShown.current = true;
        }
    }
    useEffect(() => {
        return () => {
            clearTimeout(timer);
        };
    });

    const formatTime = (timestamp: number) => {
        const secondsLeft = timestamp % 60;
        return Math.floor(timestamp / 60) + ':' + (secondsLeft < 10 ? '0' + secondsLeft : secondsLeft);
    };

    return (
        <React.Fragment>
            {configRequest && configRequest[0].isFinished && config && config.success === 1 && (
                <React.Fragment>
                    {typeof redirect !== 'undefined' && redirect.to && <RedirectAbsolute to={redirect.to} />}
                    {timeRemaining > 0 && (
                        <TimeRemaining>
                            {t('Prescription products will expire in ')} <b>{formatTime(timeRemaining)}</b>
                        </TimeRemaining>
                    )}
                    <TimeRunningOut renewTime={renewTime} timeRemaining={Math.ceil(timeRemaining / 60)} />
                    <ClosingTime timeRemaining={Math.ceil(closingTimeLeft() / 60)} />
                </React.Fragment>
            )}
        </React.Fragment>
    );
};
export default RxTimer;
